$('#wheel-filter-search select').change(function(){
  $('.accordion-filter__container').submit();
});

// show more
$('#wheels .filters').each(function(){
        
  var list = $(this).find('li');
  var button = $(this).parent().find('button');
  var numToShow = 8;
  var numInList = list.length;
  //console.log(numInList);
  list.hide();
  
  if (numInList > numToShow) {
    button.show();
  } else {
    button.hide();
  }
  list.slice(0, numToShow).show();
  
  button.click(function(){
    //console.log('lets try something new');
    var showing = list.filter(':visible').length; 
    if (showing < numInList) {
      list.slice(showing - 1, numInList).fadeIn();   
      list.css('display','list-item');
      button.text('Show Less...');
    } else if (showing == numInList) {
      list.slice(numToShow).fadeOut();
      button.text('Show All...');
    }
  });
});
// end - show more


if($('#wheels').length) {
  //////// product filters ////////

  // product filter - brand ///////////////
  // collect existing brand
  var $brands = $('#brandCollection');
  var $collBrand = $brands.val();
  // collect existing style
  var $styles = $('#styleCollection');
  var $collStyle = $styles.val();
  
  // if change update 
  $('.item-brand').change(function() {
    
    // clear existing
    $collBrand = '';
    $collStyle = '';
    
    // loop through new selection
    $('.item-brand').each(function() {
      if(this.checked) {
        $collBrand += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collBrand = trimString($collBrand);
    
    // update collection
    $brands.val($collBrand);
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedbrand").click(function() {
    console.log('cllick');
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collBrand = removeVal($collBrand,val)    
    $collStyle = '';
    
    // replace values
    $brands.val($collBrand);
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });

  // product filter - series ///////////////
  // collect existing series
  var $series = $('#seriesCollection');
  var $collSeries = $series.val();
  
  // if change update 
  $('.item-series').change(function() {
    
    // clear existing
    $collSeries = '';
    
    // loop through new selection
    $('.item-series').each(function() {
      if(this.checked) {
        $collSeries += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collSeries = trimString($collSeries);
    
    // update collection
    $series.val($collSeries);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedseries").click(function() {
    console.log('cllick');
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collSeries = removeVal($collSeries,val)    
    $collStyle = '';
    
    // replace values
    $series.val($collSeries);
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });

  // product filter - diameter ///////////////
  // collect existing diameter
  var $diameters = $('#diameterCollection');
  var $collDiameter = $diameters.val();
  
  // if change update 
  $('.item-diameter').change(function() {
    
    // clear existing
    $collDiameter = '';
    
    // loop through new selection
    $('.item-diameter').each(function() {
      if(this.checked) {
        $collDiameter += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collDiameter = trimString($collDiameter);
    
    // update collection
    $diameters.val($collDiameter);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selecteddiameter").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collDiameter = removeVal($collDiameter,val)    
    
    // replace values
    $diameters.val($collDiameter);
    
    // submit form
    $('.filter-form').submit();
  });

  // product filter - width ///////////////
  // collect existing width
  var $widths = $('#widthCollection');
  var $collWidth = $widths.val();
  
  // if change update 
  $('.item-width').change(function() {
    console.log('now me change');
    
    // clear existing
    $collWidth = '';
    
    // loop through new selection
    $('.item-width').each(function() {
      if(this.checked) {
        $collWidth += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collWidth = trimString($collWidth);
    
    // update collection
    $widths.val($collWidth);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedwidth").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collWidth = removeVal($collWidth,val)    
    
    // replace values
    $widths.val($collWidth);
    
    // submit form
    $('.filter-form').submit();
  });

  // product filter - finish ///////////////
  // collect existing finish
  var $finishes = $('#finishCollection');
  var $collFinish = $finishes.val();
  
  // if change update 
  $('.item-finish').change(function() {
    
    // clear existing
    $collFinish = '';
    
    // loop through new selection
    $('.item-finish').each(function() {
      if(this.checked) {
        $collFinish += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collFinish = trimString($collFinish);
    
    // update collection
    $finishes.val($collFinish);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedfinish").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collFinish = removeVal($collFinish,val)    
    console.log($collFinish);
    
    // replace values
    $finishes.val($collFinish);
    
    // submit form
    $('.filter-form').submit();
  });

  // product filter - bolt patterns ///////////////
  // collect existing bolt patterns
  var $bolts = $('#boltCollection');
  var $collBolt = $bolts.val();
  
  // if change update 
  $('.item-bolt').change(function() {
    
    // clear existing
    $collBolt = '';
    
    // loop through new selection
    $('.item-bolt').each(function() {
      if(this.checked) {
        $collBolt += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collBolt = trimString($collBolt);
    
    // update collection
    $bolts.val($collBolt);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedbolt").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collBolt = removeVal($collBolt,val)    
    console.log($collBolt);
    
    // replace values
    $bolts.val($collBolt);
    
    // submit form
    $('.filter-form').submit();
  });

  // product filter - offset ///////////////
  // collect existing offset
  var $offsets = $('#offsetCollection');
  var $colloffset = $offsets.val();
  
  // if change update 
  $('.item-offset').change(function() {
    
    // clear existing
    $colloffset = '';
    
    // loop through new selection
    $('.item-offset').each(function() {
      if(this.checked) {
        $colloffset += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $colloffset = trimString($colloffset);
    
    // update collection
    $offsets.val($colloffset);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedoffset").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collBolt = removeVal($colloffset,val)    
    console.log($colloffset);
    
    // replace values
    $offsets.val($colloffset);
    
    // submit form
    $('.filter-form').submit();
  });

  // product filter - price ///////////////
  // collect existing price
  var $prices = $('#priceCollection');
  var $collPrice = $prices.val();
  
  // if change update 
  $('.item-price').change(function() {
    
    // clear existing
    $collPrice = '';
    
    // loop through new selection
    $('.item-price').each(function() {
      if(this.checked) {
        $collPrice += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collPrice = trimString($collPrice);
    
    // update collection
    $prices.val($collPrice);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedprice").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collPrice = removeVal($collPrice,val)    
    console.log($collPrice);
    
    // replace values
    $prices.val($collPrice);
    
    // submit form
    $('.filter-form').submit();
  });


  // clear selection ///////////////
  $('.filter-active-clear a').click(function(){
    // remove values
    $diameters.val('');
    $widths.val('');
    $bolts.val('');
    $series.val('');
    $finishes.val('');
    $offsets.val('');
    $prices.val('');
    
    // submit form
    $('.filter-form').submit();
  });
}


// helpful functions
function trimString(obj){
  var L = (obj.length -1);
  obj = obj.substring(0, L);
  return obj;  
}
function removeVal(obj,value){
   var arrObj = obj.split(',')
   if(arrObj.length > 1){
   for( var i = 0; i < arrObj.length; i++){ 
           if ( arrObj[i] === value) {
                  arrObj.splice(i, 1); 
           }
   }
   obj = arrObj.join(",")
   }else{
        obj ='';
   }
   return obj;
}