// build items array
function buildItems() {
  let items = [];
  $('.wheel-select .item-information').each(function(){
    let item = {};
    item.id = $(this).data('itemId');
    item.price = $(this).data('itemPrice');
    item.url = $(this).data('itemUrl');
    item.description = $(this).data('itemDescription');
    item.image = $(this).data('itemImage');
    item.name = $(this).data('itemName');
    item.quantity = $(this).data('itemQuantity');
    items.push(item);
  });

  return items;
}

console.log($('.item-information').length);


// snipcart
document.addEventListener('snipcart.ready', () => {
  // You can safely use window.Snipcart here

  $('.add-to-cart').click(function(){
    let products = buildItems();
    checkCart(products);    
  });

  function checkCart(products) {
    let cartList = Snipcart.store.getState().cart.items.items;
    let idList = cartList.map(e => ({id:e.id,uniqueId:e.uniqueId,quantity:e.quantity}));
    let updateList = [];
    let newList = [];

    for (let i = 0; i < products.length; i++ ){
      let itemChecked = cartList.find(e => e.id === products[i].id);
      if(itemChecked) {
        updateList.push(products[i]);
      } else {
        newList.push(products[i]);
      }
    }
    
    // update cart
    if(updateList.length) {
      updateCart(updateList,idList);
    }
    
    // add to cart
    addToCart(newList);
  }
  
  function addToCart(items) {
    for(var i = 0; i < items.length; i++) {
      Snipcart.api.cart.items.add(items[i]);
    }
    openTheCart();
  }

  function updateCart(items,cart) {
    for(var i = 0; i < items.length; i++) {
      let cartMatch = cart.find(e => e.id === items[i].id);
      let qty = parseInt(cartMatch.quantity) + parseInt(items[i].quantity);
      let update = {
        uniqueId: cartMatch.uniqueId,
        quantity: qty};
      Snipcart.api.cart.items.update(update);
    }
    openTheCart();
  }

  function openTheCart(){
    Snipcart.api.theme.cart.open();
  }

});