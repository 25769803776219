function moveElement(detachingEle, attachingEle) {
  let img = $('.' + detachingEle).detach();
  $('.' + attachingEle).append(img);
}

let elementToMove = 'js-move-finishes';
let desktopAnchor = 'wheel-detail-stage';
let mobileAnchor = 'wheel-detail-info';

if ($('.' + elementToMove).length) {
  if (Foundation.MediaQuery.current === 'small') {
    moveElement(elementToMove, mobileAnchor);
  }
  $('.' + elementToMove).removeClass('hide');

  $(window).on('changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.atLeast('medium')) {
      moveElement(elementToMove, desktopAnchor);
    } else {
      moveElement(elementToMove, mobileAnchor);
    }
  });
}


// additional wheels
$(window).load(function(){
  var $showNumber = 6;
  if($(window).width() <= 667) {
    $showNumber = 1;
  } else {
    $showNumber = 7;
    $('#additional-slider').slick({
        autoplay: true,
        dots: false, 
        arrows: true,
        slidesToShow: $showNumber,
        swipeToSlide: true,
        nextArrow: '<button class="featured-next featured-arrow" aria-label="Next" type="button"><i class="fas fa-chevron-right"></i></button>',
        prevArrow: '<button class="featured-prev featured-arrow" aria-label="Prev" type="button"><i class="fas fa-chevron-left"></i></button>',
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false
            }
          }
        ]
    });
  }

});




 // Get see on vehicle image
  function detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk) {
    var urlOptions = [
      'year=' + year,
      'make=' + make,
      'model=' + model,
      'drivebody=' + encodeURIComponent(drivebody),
      'submodel=' + submodel,
      'wheel=' + wheel,
      'color=' + color,
      'fmk=' + fmk,
      'ID=' + configid
    ];
    try {
      $.getJSON('https://www.iconfigurators.app/ap-json/ap-image-fgap-fmk-part.aspx?' + urlOptions.join('&'), function getCarImage(data) {
        if (data.Result > 0) {
          car.find('img').attr('src', data.img[0].src);

          $('#vehicle-colors').html('');
          var colorIds = data.img[0].colorID;
          var colorNames = data.img[0].colorName;

          for (var index = 0; index < colorIds.length; index++) {
            if (colorIds[index] == color) {
              $('#vehicle-colors').append('<option value="' + colorIds[index] + '" selected>' + colorNames[index] + '</option>');
            } else {
              $('#vehicle-colors').append('<option value="' + colorIds[index] + '">' + colorNames[index] + '</option>');
            }
          }

					// Create body style select
          $('#vehicle-body').html('');
          var bodyNames = data.img[0].BodyStyle;

          for (var j = 0; j < bodyNames.length; j++) {
            if (bodyNames[j] === body) {
              $('#vehicle-body').append('<option data-id="' + j + '" value ="' + bodyNames[j] + '" selected>' + bodyNames[j] + '</option>');
            } else {
              $('#vehicle-body').append('<option data-id="' + j + '" value ="' + bodyNames[j] + '" >' + bodyNames[j] + '</option>');
            }
          }

          $('#see-on-vehicle-detail > a').attr('href', function replaceUrl(i, a) {
            return a.replace(/(&body=)([0-9]+)?(&color=)([0-9]+)?/ig, '$1' + $('#vehicle-body').find(':selected').data('id') + '$3' + $('#vehicle-colors').val());
          });

				}	else {
          car.find('.img-wrapper')
            .addClass('no-fitment')
            .find('.error')
              .text('We do not currently have an image of this wheel on your vehicle. Check back soon.');
        }
      });
    } catch (err) {
      car.find('.img-wrapper')
        .addClass('no-fitment')
        .find('.error')
          .text('Error Loading Image for Your Vehicle');
    }
  }

  // Set Dropdowns
  function setDropdownHandlers(car, year, make, model, drivebody, submodel, configid, fmk) {
    // Bind the color select
    $('#vehicle-colors').change(function changeColor() {
      var body = $('#vehicle-body').val();
      var color = $('#vehicle-colors').val();
      var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
      $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
        return a.replace(/(&color=)([0-9]+)?/ig, '$1' + color);
      });
      detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk);
    });
		// Bind the body select
    $('#vehicle-body').change(function changeBody() {
      var body = $('#vehicle-body').val();
      var color = 0;
      var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
      $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
        return a.replace(/(&body=)([0-9]+)?(&color=)([0-9]+)?/ig, '$1' + $('#vehicle-body').find(':selected').data('id') + '$3');
      });
      detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk);
    });
  }

  // Check if vehicle is set in session first
  if ($('#see-on-vehicle-detail').length) {
    var car = $('#see-on-vehicle-detail');
    var year = car.data('year');
    var make = car.data('make');
    var model = car.data('model');
    var drivebody = car.data('drivebody');
    var submodel = car.data('submodel');
    var wheel = car.attr('data-wheel');
    var configid = car.data('config');
    var option = car.data('option');
    var fmk = car.data('option');
    if (typeof option === 'undefined') {
      option = '';
    }
    detailCarImage(car, year, make, model, drivebody, submodel, wheel, 0, '', configid, fmk);
    setDropdownHandlers(car, year, make, model, drivebody, submodel, configid, fmk);
  }



// cart dropdown
/*
$('.cart-filter select option:select') {
  console.log('we have something here')
}*/
$('.cart-filter select').change(function changeSpecs() {
  let current = $(this).attr('name');
  $(this).css('color','black');
  //let currentStaggered = $(this).parent().parent().parent();
  
  // wheel
  let wheelID = $('input[name="wheelID"]').val();

  // size
  let size = $('select[name="size"] option:selected').val();
  if(typeof size == 'undefined') {
    size = '';
  }
  // finish
  let finish = $('select[name="finish"] option:selected').val();
  if(typeof finish == 'undefined') {
    finish = '';
  } else if(current == 'finish') {
    let stageUrl = '/ajax/getStage.cfm?id=' + wheelID +
    '&finishID=' + finish;

    $.get(stageUrl, function stageDisplay(
      data
    ) {
      $('.wheel-detail-stage').animate({opacity:0},300,function(){
        $('.wheel-detail-stage-container').html(data);
      }).delay(100).animate({opacity:1},600);
      /*
      $('.wheel-detail-stage').fadeTo(400, 0.1, function() {
        $('.alt-images').css('visbitlity','hidden');
        $('.wheel-detail-stage-container').html(data).fadeIn(function(){
          $('.alt-images').fadeIn();
        });
        $(this).fadeTo(400,1);
      });*/
      /*
      $('.wheel-detail-stage-container').fadeTo(400, 0, function() {
        $(this).html(data).fadeIn();
        //$('.alt-images').fadeIn();
      })//.fadeTo(400, 1, function() {
        //$('.wheel-detail-stage-container').html(data);
        //$('.alt-images').fadeIn();
     // });
        //.html(data);
        */
    });
  }
  // offset
  let offset = $('select[name="offset"] option:selected').val();
  if(typeof offset == 'undefined') {
    offset = '';
  }
  // bolt pattern
  let bolt = $('select[name="bolt"] option:selected').val();
  if(typeof bolt == 'undefined') {
    bolt = '';
  }

  if(current == 'finish') {
    size = '';
    offset = '';
    bolt = '';
  }

  // rear information
  let rear = $('.rear').hasClass('wheel-select');

  // size
  let rSize = $('select[name="rSize"] option:selected').val();
  if(typeof rSize == 'undefined') {
    rSize = '';
  }
  // finish
  let rFinish = $('select[name="rFinish"] option:selected').val();
  if(typeof rFinish == 'undefined') {
    rFinish = '';
  }
  // offset
  let rOffset = $('select[name="rOffset"] option:selected').val();
  if(typeof rOffset == 'undefined') {
    rOffset = '';
  }
  // bolt pattern
  let rBolt = $('select[name="rBolt"] option:selected').val();
  if(typeof rBolt == 'undefined') {
    rBolt = '';
  }

  if(current == 'rFinish') {
    rSize = '';
    rOffset = '';
    rBolt = '';
  }

  
  let url = '/ajax/getCartSpecs.cfm?wheelID=' + wheelID + 
    '&size=' + size + 
    '&finish=' + finish + 
    '&offset=' + offset + 
    '&bolt=' + bolt + 
    '&rear=' + rear;
  if (rear == true) {
    url = url + '&rSize=' + rSize +
      '&rFinish=' + rFinish + 
      '&rOffset=' + rOffset + 
      '&rBolt=' + rBolt;
    }
  $.get(url, function getSpecs(
    data
  ) {
    $('.details-cart .cart-selection')
      .html(data)
      .prop('disabled', false);
  });
  
});

// cart - staggered display
$('.staggered-button').click(function(){
  let staggered = $('.staggered');
  let staggeredDivider = $('.cart-staggered.divider');
  let frTitle = $('.front-rear .staggered-title');
  let frontFinish = $('#finish :selected');
  let rearFinish = $('#rFinish');
  let rearSize = $('#rSize');
  let frontQuantity = $('#fQuantity');
  let frontQuantityContainer = frontQuantity.parent().parent().parent();
  let frontRearQuantity = $('#frQuantity');
  let frontRearQuantityContainer = frontRearQuantity.parent().parent().parent();
  let rearQuantity = $('#rQuantity');

  if(staggered.hasClass('wheel-select') && !staggered.hasClass('hide')) {
    staggered.removeClass('wheel-select');
    staggered.addClass('hide');
    $(this).html('staggered fitment available');
    staggeredDivider.addClass('hide');
    frTitle.html('front and rear').addClass('hide');
    frontQuantityContainer.addClass('hide');
    frontRearQuantityContainer.removeClass('hide');
    frontRearQuantity.val(4);
  } else {
    staggered.addClass('wheel-select');
    staggered.removeClass('hide');
    $(this).html('remove staggered fitment');
    $(this).find('staggered-not-active').addClass('hide');
    $(this).find('staggered-active').removeClass('hide');
    staggeredDivider.removeClass('hide');
    frTitle.html('front').removeClass('hide');
    if(rearFinish.val() == '') {
      rearFinish.val(frontFinish.val()).css('color','black');
      rearSize.prop('disabled',false);
    }
    frontQuantityContainer.removeClass('hide');
    frontRearQuantityContainer.addClass('hide');
    frontQuantity.val(2);
    rearQuantity.val(2);
  }


  // check submit display
  let fieldCount = $('.wheel-select .field select').length;
  let totalField = 0;
  $('.wheel-select .field select').each(function(){
    let isEmpty = $(this).val();
    if(isEmpty !== '') {
      totalField++;
    } 
  });

  if(fieldCount == totalField) {
    $('.details-cart button').prop("disabled", false);
  } else {
    $('.details-cart button').prop("disabled", true);
  }
});


// quantity updates
$('#fQuantity').change(function(){
  let fQuantity = $(this).val();
  let frQuantity = $('#frQuantity');

  $('.front-rear-information').attr('data-item-quantity',fQuantity);
  frQuantity.val(fQuantity);
});
$('#frQuantity').change(function(){
  let frQuantity = $(this).val();
  let fQuantity = $('#fQuantity');

  $('.front-rear-information').attr('data-item-quantity',frQuantity);
  fQuantity.val(frQuantity);
});
$('#rQuantity').change(function(){
  let rearQuantity = $(this).val();
  $('.rear-information').attr('data-item-quantity',rearQuantity);
});



