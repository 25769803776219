$('.js-gallery-main-img').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

$('.js-gallery-main-img').slick({
  adaptiveHeight: false,
  arrows: true,
  nextArrow: '<button class="gallery-next gallery-arrow" aria-label="Next" type="button"><img src="/images/utility/arrow-right.png" /></button>',
  prevArrow: '<button class="gallery-prev gallery-arrow" aria-label="Prev" type="button"><img src="/images/utility/arrow-left.png" /></button>',
  asNavFor: '#gallery-thumbs',
  fade: true,
  lazyLoad: 'ondemand',
  responsive: [{
    breakpoint: 600,
    settings: {
      arrows: false,
    }
  }]
});
$('#gallery-thumbs').slick({
  arrows: false,
  asNavFor: '.js-gallery-main-img',
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll:5,
  responsive: [{
    breakpoint: 600,
    settings: {
      dots: true,
      slidesToScroll: 3,
      slidesToShow: 3
    }
  }]
});

$('.gallery-featured .thumbnail').slick({
  
});

$(function () {
  // Header Filters
  $('#gallery-filter select').change(function changeBrand() {
    $('#gallery-search form').submit();
  });

})



/// gallery submit
if($('#contact.gallery-submit, #contact.warranty-submit').length) {
  /////// Vehicle Information //////
  $('#vYear').change(function(){
    var makeField = $('#vMake');
    makeField.prop('disabled', false);
  });
  
  $('#vMake').change(function(){
    var selected = $(this).children("option:selected").val();
    var modelField = $('#vModel');
    
    // implemeent model selections
    $.get('/ajax/getGallerySubmitModels.cfm?model=' +selected, function getStates(data) {
      modelField.html(data).prop('disabled', false);
    });
  });
  
  /////// Wheel Information ///////
  // wheel model
  $('#wModel').change(function(){
    var selected = $(this).children("option:selected").val();
    var nextField = $('#wFinish');
    
    // implemeent model selections
    $.get('/ajax/getGallerySubmitFinishes.cfm?wheel=' +selected, function(data) {
      nextField.html(data).prop('disabled', false);
    });
    
    $('#wSizeFront').prop('disabled', true);
    $('#wSizeRear').prop('disabled', true);
    
  });
  // wheel finish
  $('#wFinish').change(function(){
    console.log('wheel finish change');
    var wModel = $('#wModel').val();
    var selected = $(this).children("option:selected").val();
    var nextField = $('#wSizeFront');
    
    // implemeent model selections
    $.get('/ajax/getGallerySubmitSizeFront.cfm?wheel=' +wModel+ '&finish=' +selected, function(data) {
      nextField.html(data).prop('disabled', false);
    });
    
    $('#wSizeRear').prop('disabled', true);
    
  });
  $('#wSizeFront').change(function(){
    console.log('wheel finish change');
    var wModel = $('#wModel').val();
    var wFinish = $('#wFinish').val();
    var nextField = $('#wSizeRear');
    
    // implemeent model selections
    $.get('/ajax/getGallerySubmitSizeRear.cfm?wheel=' +wModel+ '&finish=' +wFinish, function(data) {
      nextField.html(data).prop('disabled', false);
    });
    
  });
  
  
  /*
  var endWI = 4;
  $('.wheel-info-field').change(function(){
    var thisClasses = $(this).attr('class');
    var thisClass = thisClasses.replace('wheel-info-field ','');
    var thisNumber = thisClass.match(/\d+/)[0];
    thisNumber = parseInt(thisNumber,10);
    var nextNumber = thisNumber + 1;
    var nextField = 'wi-' + nextNumber;
    
    // find a match and set it free
    if ( thisNumber != endWI ) {
      $('.wheel-info-field').each(function(){
        var className = $(this).attr('class');
        if ( className.includes(nextField) ) {
          $(this).prop('disabled',false);
        }
      });
    };
  });
  */
  
}


// Event Gallery Details 
$('.event-gallery-detail .event-gallery')
    .magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1],
        dots: false,
      },
    });